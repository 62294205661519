<script setup lang="ts">
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { PiHcGift } from '@primeinsightsgroupllc/prime-icons';
import UserBalance from './UserBalance.vue';
import UserStreak from './UserStreak.vue';
import UserLevel from './level/UserLevel.vue';
import { DAILY_STREAK_MODAL, REWARDS_MODAL } from '@/constants/modals';
import { CLAIM_REWARD } from '@/locales/constants';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { isMobile, isTablet } = useScreenSize();
const { createNewModal } = useModalStorage();

const openDayStreakModal = () => {
	createNewModal(DAILY_STREAK_MODAL);
};

const openRewardsModal = () => {
	createNewModal(REWARDS_MODAL, {
		position: 'bottom',
	});
};
</script>

<template>
	<div class="widget-top-box">
		<UserLevel v-if="isMobile || isTablet" />
		<div class="widget-box-middle">
			<UserStreak @click="openDayStreakModal" />
			<UserBalance />
		</div>
		<PrimeButton
			:label="$t(CLAIM_REWARD)"
			type="fill"
			full-width
			:size="isMobile ? 'small' : 'normal'"
			@click="openRewardsModal"
		>
			<template #iconLeft> <PiHcGift /> </template>
		</PrimeButton>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.widget-top-box {
	padding: 0.75rem 0.875rem;
	border: 1px solid var(--extra-dark-green-2);
	border-radius: 1.5rem;
	background: var(--extra-box-gradient);
	box-shadow: var(--extra-box-shadow-inset);

	.widget-box-middle {
		display: flex;
		justify-content: space-between;
		gap: 1.5rem;
		margin: 0.75rem 0;
	}

	@include breakpoint(tablet) {
		padding: 1.5rem 0.875rem;

		.widget-box-middle {
			flex-direction: column;
			justify-content: unset;
			margin: 0;
			gap: 2.25rem;
			margin-bottom: 3.375rem;
		}
	}
}
</style>
