<script setup lang="ts">
import { ref, watch } from 'vue';
import ModalCommonContent from '@/components/common/ModalCommonContentNew.vue';
import {
	BONUS_CODE,
	BONUS_CODE_MESSAGE,
	SUBMIT,
	CONGRATULATIONS,
	GOTCHA,
} from '@/locales/constants';
import {
	PrimeInput,
	PrimeButton,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { getErrorResponse } from '@/utils/helpers';
import { redeemBonusCode } from '@/api';
import congratulations from '@/assets/congratulations.svg';

const code = ref();
const errorMessage = ref('');
const loading = ref(false);
const isCodeInvalid = ref<'error' | null>(null);
const codeSubmitted = ref(false);
const successSubmitMessage = ref('');

const handleSubmit = async () => {
	try {
		loading.value = true;
		const { status, data } = await redeemBonusCode(code.value);
		if (status === 200) {
			codeSubmitted.value = true;
			successSubmitMessage.value = data.message;
		}
	} catch (e) {
		errorMessage.value = getErrorResponse(e).message;
		isCodeInvalid.value = 'error';
	} finally {
		loading.value = false;
	}
};

watch(code, (newValue) => {
	if (isCodeInvalid.value === 'error' && newValue) {
		errorMessage.value = '';
		isCodeInvalid.value = null;
	}
});
</script>

<template>
	<ModalCommonContent v-if="!codeSubmitted" class="bonus-code-modal">
		<template #header>{{ $t(BONUS_CODE) }}</template>
		<form class="bonus-code-modal-content" @submit.prevent="handleSubmit">
			<PrimeText
				weight="500"
				color="white"
				align="left"
				class="bonus-code-teaser"
			>
				{{ $t(BONUS_CODE_MESSAGE) }}
			</PrimeText>
			<PrimeInput
				v-model="code"
				type="text"
				:placeholder="$t(BONUS_CODE)"
				:invalid="!!isCodeInvalid"
				:error-text="errorMessage"
				:disabled="loading"
			/>
			<PrimeButton
				:label="$t(SUBMIT)"
				class="bonus-code-btn"
				role="submit"
				:disabled="!code || !!isCodeInvalid"
				:loading="loading"
			/>
		</form>
	</ModalCommonContent>
	<ModalCommonContent v-else>
		<template #header>{{ $t(BONUS_CODE) }}</template>
		<template #icon>
			<img
				:src="congratulations"
				alt="Congratulation icon"
				class="congratulation-icon"
			/>
		</template>
		<div class="congrats-bonus-content">
			<PrimeText
				weight="500"
				color="white"
				align="left"
				size="lg"
				class="bonus-code-teaser"
			>
				{{ $t(CONGRATULATIONS) }}
			</PrimeText>
			<PrimeText
				weight="500"
				color="grey-700"
				align="left"
				class="bonus-code-teaser"
			>
				{{ successSubmitMessage }}
			</PrimeText>
			<PrimeButton
				:label="$t(GOTCHA)"
				class="bonus-code-btn"
				@click="$emit('close-modal')"
			/>
		</div>
	</ModalCommonContent>
</template>

<style scoped lang="scss">
.bonus-code-modal-content {
	display: flex;
	flex-direction: column;
	text-align-last: left;
	width: 100%;
	margin-top: 1.5rem;
}
.bonus-code-teaser {
	margin-bottom: 0.5rem;
	display: inline-block;
}
.bonus-code-btn {
	align-self: center;
	margin-top: 1.5rem;
}
.congrats-bonus-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
	text-align: center;
}

.heycash-modal .content-icon img.congratulation-icon {
	width: 11.25rem;
}
</style>
