<script lang="ts" setup>
import { useNavigation } from '@/components/common/header/composables';
import { Nav } from '@/enums';
import { PrimeNavigation } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { NavItem } from '@/types';

const { items, isOpen, navigationClick } = useNavigation(Nav.DESKTOP);
</script>

<template>
	<div class="desktop-nav">
		<PrimeNavigation
			v-for="item in items"
			:key="item.id"
			color="grey-700"
			:data="{ ...item, isOpen }"
			@navigation-click="navigationClick($event as NavItem)"
		/>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.desktop-nav {
	position: static;
	z-index: 1;
	display: none;
	flex-direction: column;
	margin: 0 auto;
	gap: 0.75rem;

	@include breakpoint(tablet) {
		display: flex;
		gap: 0.625rem;
	}

	.p-nav-item {
		border-radius: 0.5rem !important;
		color: var(--p-grey-700) !important;
		&:hover {
			color: var(--base-primary) !important;
		}

		.p-nav-pin {
			padding: 0.125rem 0.75rem;
			border-radius: var(--p-radius-2xl);
			background-color: #806dff;
			font-size: 0.75rem;
			font-weight: 500;
			color: var(--p-white);
		}
	}

	.p-is-active {
		.p-nav-item {
			color: var(--base-primary) !important;
		}
	}

	.desktop-nav-link {
		letter-spacing: -0.16px;
		display: inline-flex;
		align-items: center;
		color: var(--p-grey-700);

		& > span {
			color: inherit;
		}

		@include breakpoint(laptop) {
			gap: 0.5rem;
		}

		&:hover,
		&.router-link-active {
			color: var(--base-primary);
		}
	}
}
</style>
