import type { Component } from 'vue';
import {
	DELETE_ACCOUNT_MODAL,
	CHANGE_USERNAME_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	BONUS_CODE_MODAL,
	DAILY_STREAK_MODAL,
	REWARDS_MODAL,
	CHANGE_LANGUAGE_MODAL,
	REFFERAL_LINK_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
	LEADERBOARD_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	DYNAMIC_ANNOUNCEMENT_MODAL,
	USER_STREAK_MODAL,
	USER_LEVEL_MODAL,
	NEW_PASSWORD_SENT,
	SET_USER_PASSWORD_MODAL,
	CONFIRM_CLAIM_DATA_MODAL,
	UNLOCK_OFFERWALLS_MODAL,
	OFFERWALLS_PENDING_MODAL,
	REWARD_SENT_MODAL,
	CONFIRM_CLAIM_MODAL,
	ONE_CLICK_SURVEY_MODAL,
	WELCOME_BONUS_MODAL,
} from '@/constants/modals';

import DeleteAccountModal from '@/components/modals/DeleteAccountModal.vue';
import ChangeUsernameModal from '@/components/modals/ChangeUsernameModal.vue';
import ChangeTimezoneModal from '@/components/modals/ChangeTimezoneModal.vue';
import ChangeEmailModal from '@/components/modals/ChangeEmailModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
import BonusCodeModal from '@/components/modals/BonusCodeModal.vue';
import DailyStreak from '@/components/common/header/DailyStreak.vue';
import RewardsModal from '@/components/modals/RewardsModal.vue';
import ChangeLanguageModal from '@/components/modals/ChangeLanguageModal.vue';
import RefferalLinkModal from '@/components/modals/RefferalLinkModal.vue';
import OfferwallsWellDoneModal from '@/components/modals/OfferwallsWellDoneModal.vue';
import LeaderboardModal from '@/components/modals/LeaderboardModal.vue';
import OfferwallRewardNotificationModal from '@/components/modals/notifications/OfferwallRewardNotificationModal.vue';
import DynamicAnnouncementModal from '@/components/modals/announcements/DynamicAnnouncementModal.vue';
import UserCongratsStreakModal from '@/components/modals/CongratsStreakModal.vue';
import UserCongratsLevelModal from '@/components/modals/CongratsLevelModal.vue';
import NewPasswordSentModal from '@/components/modals/NewPasswordSentModal.vue';
import SetUserPasswordModal from '@/components/modals/SetUserPasswordModal.vue';
import ConfirmPaypalEmailModal from '@/components/modals/ConfirmClaimDataModal.vue';
import UnlockOfferwallsModal from '@/components/modals/UnlockOfferwallsModal.vue';
import OfferwallsPendingModal from '@/components/modals/OfferwallsPendingModal.vue';
import RewardSentModal from '@/components/modals/RewardSentModal.vue';
import ConfirmClaimModal from '@/components/modals/ConfirmClaimModal.vue';
import OneClickSurveyModal from '@/components/modals/OneClickSurveyModal.vue';
import WelcomeBonusModal from '@/components/modals/WelcomeBonusModal.vue';

export const MODAL_MAP: Record<string, Component> = {
	[DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
	[CHANGE_USERNAME_MODAL]: ChangeUsernameModal,
	[CHANGE_TIMEZONE_MODAL]: ChangeTimezoneModal,
	[CHANGE_EMAIL_MODAL]: ChangeEmailModal,
	[CHANGE_PASSWORD_MODAL]: ChangePasswordModal,
	[BONUS_CODE_MODAL]: BonusCodeModal,
	[DAILY_STREAK_MODAL]: DailyStreak,
	[REWARDS_MODAL]: RewardsModal,
	[CHANGE_LANGUAGE_MODAL]: ChangeLanguageModal,
	[REFFERAL_LINK_MODAL]: RefferalLinkModal,
	[OFFERWALLS_WELL_DONE_MODAL]: OfferwallsWellDoneModal,
	[LEADERBOARD_MODAL]: LeaderboardModal,
	[OFFERWALLS_REWARD_NOTIFICATION_MODAL]: OfferwallRewardNotificationModal,
	[DYNAMIC_ANNOUNCEMENT_MODAL]: DynamicAnnouncementModal,
	[USER_STREAK_MODAL]: UserCongratsStreakModal,
	[USER_LEVEL_MODAL]: UserCongratsLevelModal,
	[SET_USER_PASSWORD_MODAL]: SetUserPasswordModal,
	[NEW_PASSWORD_SENT]: NewPasswordSentModal,
	[CONFIRM_CLAIM_DATA_MODAL]: ConfirmPaypalEmailModal,
	[UNLOCK_OFFERWALLS_MODAL]: UnlockOfferwallsModal,
	[OFFERWALLS_PENDING_MODAL]: OfferwallsPendingModal,
	[REWARD_SENT_MODAL]: RewardSentModal,
	[CONFIRM_CLAIM_MODAL]: ConfirmClaimModal,
	[ONE_CLICK_SURVEY_MODAL]: OneClickSurveyModal,
	[WELCOME_BONUS_MODAL]: WelcomeBonusModal,
};
