<script lang="ts" setup>
import type { Modal } from '@/types';
import type { Component } from 'vue';
import { watch, ref, onMounted, onBeforeUnmount } from 'vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useDocumentVisibility } from '@vueuse/core';
import { useMessagesStore } from '@/stores/messages';
import {
	PRIZE_DRAW_MODAL,
	SELECT_COUNTRY_LANGUAGE_MODAL,
	NEW_LEVELS_MODAL,
	GOOGLE_TRANSLATE_MODAL,
	PRIZE_BONUS_MODAL,
	ASK_TRACKING_PERMISSIONS_MODAL,
	IOS_BROKEN_APP_MODAL,
	UPDATE_AVAILABLE_MODAL,
} from '@/constants/modals';
import ModalWrapper from '@/components/common/ModalWrapper.vue';
import PrizeDrawModal from '@/components/modals/PrizeDrawModal.vue';
import SelectCountryLanguageModal from '@/components/modals/SelectCountryLanguageModal.vue';
import NewLevelsModal from '@/components/modals/NewLevelsModal.vue';
import GoogleTranslateModal from '@/components/modals/GoogleTranslateModal.vue';
import PrizeBonusModal from '@/components/modals/PrizeBonusModal.vue';
import { MessageChannels } from '@/enums';
import AskTrackingPermissionsModal from '@/components/modals/AskTrackingPermissionsModal.vue';
import IosBrokenAppModal from '@/components/modals/IosBrokenAppModal.vue';
import UpdateAvailableModal from '@/components/modals/UpdateAvailableModal.vue';

const MODAL_MAP: Record<string, Component> = {
	[PRIZE_DRAW_MODAL]: PrizeDrawModal,
	[SELECT_COUNTRY_LANGUAGE_MODAL]: SelectCountryLanguageModal,
	[NEW_LEVELS_MODAL]: NewLevelsModal,
	[GOOGLE_TRANSLATE_MODAL]: GoogleTranslateModal,
	[PRIZE_BONUS_MODAL]: PrizeBonusModal,
	[ASK_TRACKING_PERMISSIONS_MODAL]: AskTrackingPermissionsModal,
	[IOS_BROKEN_APP_MODAL]: IosBrokenAppModal,
	[UPDATE_AVAILABLE_MODAL]: UpdateAvailableModal,
};

const messageStore = useMessagesStore();
const visibility = useDocumentVisibility();
const { shiftModal, storage, activeModal } = useModalStorage();
const modal = ref<Modal | undefined>(undefined);
const showModal = ref(false);
const timeout = ref<any>(null);

const sendReadEvent = async () => {
	if (modal.value?.options?.id && !modal.value?.options?.achievement_key) {
		switch (modal.value?.options?.channel) {
			case MessageChannels.NOTIFICATION:
				await messageStore.readNotification(modal.value.options.id);
				break;
			case MessageChannels.ANNOUNCEMENT:
				await messageStore.readAnnouncement(modal.value.options.id);
				break;
			default:
				break;
		}
	}
};

const handleCloseModal = async () => {
	if (visibility.value === 'visible') {
		try {
			await sendReadEvent();
		} finally {
			await shiftModal();
		}
		try {
			await sendReadEvent();
		} finally {
			await shiftModal();
		}
	}
};

onMounted(async () => {
	if (
		visibility.value === 'visible' &&
		!Object.keys(activeModal.value).length &&
		storage.value.length
	) {
		await shiftModal();
	}
});

watch(
	() => storage.value,
	async (value) => {
		if (visibility.value === 'visible' && value.length && !modal.value) {
			await shiftModal();
		}
	}
);

watch(visibility, async (value) => {
	if (value === 'visible' && !modal.value && storage.value.length) {
		await shiftModal();
	}
});

watch(
	activeModal,
	(value) => {
		if (Object.keys(value).length) {
			modal.value = { ...value };
			showModal.value = Boolean(modal.value);
		} else {
			showModal.value = false;

			if (modal.value?.options?.position === 'bottom') {
				// Set timeout for transition duration on close
				timeout.value = setTimeout(() => {
					modal.value = undefined;
					clearTimeout(timeout.value);
				}, 550);
			} else {
				modal.value = undefined;
			}
		}
	},
	{ immediate: true }
);

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});
</script>

<template>
	<ModalWrapper
		:show="showModal"
		:use-default-close="modal?.options?.useDefaultClose ?? true"
		:position="modal?.options?.position"
		:data-test-id="modal?.name || ''"
		:channel="modal?.options?.channel"
		@close-modal="handleCloseModal"
	>
		<component
			:is="{ ...MODAL_MAP[modal.name] }"
			v-if="modal?.name"
			:open="showModal"
			:options="modal.options"
			:data="modal.data"
			@close-modal="handleCloseModal"
		/>
	</ModalWrapper>
</template>
