<script lang="ts" setup>
import {
	YOUR_REWARD,
	THANK_YOU_FOR_OPINION,
	TAKE_SURVEYS_EARN_POINTS,
	POINTS,
	TAKE_SURVEYS,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContentNew.vue';
import { onMounted } from 'vue';
import { localizePoints } from '@/utils/helpers';
import { oneClickSurveyRewardStorage } from '@/utils/local-storage';
import { PiCoinsDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

type ModalOptions = {
	reward: string;
};

defineProps<{
	options: ModalOptions;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

onMounted(() => {
	oneClickSurveyRewardStorage.value = '';
});
</script>

<template>
	<div class="one-click-survey">
		<ModalCommonContent>
			<template #header>{{ $t(YOUR_REWARD) }}</template>
			<template #icon>
				<PiCoinsDuotoneThin size="100%" color="base-primary" class="scale-in" />
			</template>
			<template #title>{{ $t(THANK_YOU_FOR_OPINION) }}</template>
			<template #description>
				<PrimeText color="secondary" weight="600" transform="capitalize">
					{{ $t(YOUR_REWARD) }}
				</PrimeText>
				<br />
				<PrimeText color="secondary" weight="700" size="xl">
					{{ localizePoints(options.reward) }} {{ $t(POINTS) }}
				</PrimeText>
			</template>
			<template #message>{{ $t(TAKE_SURVEYS_EARN_POINTS) }}</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(TAKE_SURVEYS)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.one-click-survey {
	:deep(.p-modal-layout) {
		.content-title {
			margin-bottom: 0.75rem;
		}

		.content-icon {
			margin-bottom: 0.75rem;
		}

		.content-description {
			text-align: center;
			margin-bottom: 1.375rem;
			padding-bottom: 1.375rem;
			max-width: 100%;
		}
	}
}
</style>
