<script lang="ts" setup>
import {
	CONGRATS,
	GOTCHA,
	STREAK_GONGRATS_TITLE,
	STREAK_GONGRATS_DESCRIPTION,
} from '@/locales/constants';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContentNew.vue';
import { localizePoints } from '@/utils/helpers';
import { PiFireDuotoneThin } from '@primeinsightsgroupllc/prime-icons';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const MAX_STREAK_PRIZE = 1000;
</script>

<template>
	<ModalCommonContent id="user-congrats-streak-modal">
		<template #header>{{ $t(CONGRATS) }}</template>
		<template #icon>
			<PiFireDuotoneThin size="100%" color="base-secondary" />
		</template>
		<template #title>{{ $t(STREAK_GONGRATS_TITLE) }}</template>
		<template #message>
			{{
				$t(STREAK_GONGRATS_DESCRIPTION, {
					value: localizePoints(MAX_STREAK_PRIZE),
				})
			}}
		</template>
		<template #actions>
			<PrimeButton
				full-width
				type="gradient"
				:label="$t(GOTCHA)"
				@click="handleCloseModal"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
#user-congrats-streak-modal {
	.content-title,
	.content-message {
		.p-text {
			line-height: 1.4;
		}
	}
	.content-title {
		margin-top: 1.5rem;
		.p-text {
			font-size: 1rem;
		}
	}
	.content-message {
		.p-text {
			color: var(--grey-700);
			max-width: 100%;
			width: 100%;
			font-weight: 400;
		}
	}
}
</style>
