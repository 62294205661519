<script lang="ts" setup>
import ModalCommonContent from '@/components/common/ModalCommonContentNew.vue';
import { useRouter } from 'vue-router';
import {
	CHECK_IT_OUT,
	CONGRATS,
	LEADERBOARD_SCORED_TITLE,
	LEADERBOARD_UNLOCKED_MESSAGE,
	LEADERBOARD_UNLOCKED_TITLE,
	LEADERBOARD_WON_MESSAGE,
	WELL_DONE,
} from '@/locales/constants';
import { LeaderboardTypes } from '@/enums';
import ChangeUsernameForm from '@/components/forms/ChangeUsernameForm.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import unlock_leaderboard from '@/assets/unlock_leaderboard.svg';
import type { LeaderboardInfo } from '@/types';

type Options = {
	type: LeaderboardTypes;
	rank?: number;
	coins?: number;
	info?: LeaderboardInfo;
};

const router = useRouter();
const { username } = storeToRefs(useUserStore());

defineProps<{
	options: Options;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const goToleaderboard = async () => {
	router.push({ name: 'leaderboard' });
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent id="leaderboard-modal">
		<template #header>
			<template v-if="options.type === LeaderboardTypes.SCORED">
				{{ $t(WELL_DONE) }}
			</template>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED">{{
				$t(CONGRATS)
			}}</template>
		</template>
		<template #icon>
			<img :src="unlock_leaderboard" alt="" />
		</template>
		<template #title>
			<PrimeText
				v-if="options.type === LeaderboardTypes.UNLOCKED"
				tag="div"
				weight="500"
			>
				{{ $t(LEADERBOARD_UNLOCKED_TITLE) }}
			</PrimeText>
			<PrimeText
				v-if="options.type === LeaderboardTypes.SCORED"
				tag="div"
				weight="500"
			>
				{{ $t(LEADERBOARD_SCORED_TITLE) }}
			</PrimeText>
		</template>
		<template #message>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED && !username">
				{{ $t(LEADERBOARD_UNLOCKED_MESSAGE) }}
			</template>

			<PrimeText
				v-if="options.type === LeaderboardTypes.SCORED"
				tag="div"
				weight="600"
				color="primary"
				class="message-locked"
			>
				{{
					$t(LEADERBOARD_WON_MESSAGE, {
						value: localizePoints(options.coins || 0),
					})
				}}
			</PrimeText>
		</template>
		<template #actions>
			<template v-if="options.type === LeaderboardTypes.UNLOCKED">
				<ChangeUsernameForm v-if="!username" :handler="goToleaderboard" />
				<PrimeButton
					v-else
					type="gradient"
					full-width
					:label="$t(CHECK_IT_OUT)"
					@click="goToleaderboard"
				/>
			</template>
			<template v-if="options.type === LeaderboardTypes.SCORED">
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(CHECK_IT_OUT)"
					@click="goToleaderboard"
				/>
			</template>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
#leaderboard-modal {
	.content-title {
		justify-content: flex-start;
		margin-top: 1.5rem;

		.p-text {
			text-align: left;
			max-width: 100%;
		}
		& > div > span > span {
			display: inline-flex;
			align-items: center;
			width: 2rem;
			height: 2rem;
		}
	}

	.content-message {
		justify-content: flex-start;
		text-align: left;
		.p-text {
			color: var(--grey-700);
			max-width: 100%;
			width: 100%;
		}
	}

	.message-locked {
		color: white;
		margin-bottom: 1.5rem;

		&-prize {
			margin-top: 1.5rem;
		}

		.p-progress {
			margin-bottom: 0.25rem;
			border-radius: 3.125rem;
			background: var(--p-grey-300);
		}

		.p-progress-bar {
			margin-bottom: 1.5rem;
			border-radius: 3.125rem;
			background: linear-gradient(
				270deg,
				#ff6b34 0%,
				rgba(255, 107, 52, 0) 100%
			) !important;
		}
	}

	.content-icon {
		width: 180px;
		height: 180px;
		margin-left: auto;
		margin-right: auto;
	}

	.content-actions {
		.content-actions-button {
			margin-top: 1rem;
		}
	}
}
</style>
